import axios from 'axios';
import React from 'react';

const host = process.env.REACT_APP_API_SERVICE;

export const MailService = {

    sendMail: (message) => {
        return axios.post(host + '/emails/contact-us', {
            first_name: message.first_name,
            last_name: message.last_name,
            email: message.email,
            message: message.message
        })
    },
};
