import React, {Component, useEffect, useState} from "react";
import {Card, Image, Button, Form, Input} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import logo from "../../../../Assets/img/branding/prevengo.png";
import global from "../../../../Assets/img/contact/global_business.jpeg";
import sac from "../../../../Assets/img/contact/sac.jpeg";
import {Link} from "react-router-dom";
import {MailService} from "../services/MailService";
import ReCAPTCHA from "react-google-recaptcha";
import {NotificationManager} from "react-light-notifications";


const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 18},
};

const validateMessages = {
    required: '${label} requerido!',
    types: {
        email: 'No es un correo valido!',
    }
};


const Section = () => {
    const recaptchaRef = React.createRef();
    const [form] = Form.useForm();
    const [disabled, setDisabled] = useState(true)


    const buttonItemLayout = {
        wrapperCol: {span: 14, offset: 4},
    };

    const onFinish = (values) => {


        let message = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            message: values.message
        }
        MailService.sendMail(message)
            .then(response => {

                NotificationManager.success({
                    title: <h4 style={{color: 'white'}}>Mensaje Envidado</h4>,
                    message: "Le contestaremos lo más pronto posible",
                });

                form.resetFields();
                recaptchaRef.current.reset();


            })
            .catch(error => {
                NotificationManager.error({
                    title: <h4 style={{color: 'white'}}>Error</h4>,
                    message: error.message,
                });
            })
    };

    const onChange = (value) => {
        value ?
            setDisabled(false)
            :
            setDisabled(true)
    }

    return (
        <Container>
            <Row>
                <Col sm={12}>
                    <div className="custom-title">
                        <h2 className="decorated" style={{color: '#44d7a0'}}>
                            <span>Contáctenos</span>
                        </h2>

                    </div>

                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12}>
                    <div className="custom-paragraph-2">
                        <p style={{color: '#555555'}}>
                            El Covid-19 les deja a las empresas nuevas responsabilidades en relación con la
                            incorporación de
                            controles de bioseguridad en sus instalaciones y el monitoreo de la salud de sus
                            colaboradores
                            para la identificación temprana de casos.
                            Con este servicio las empresas podrán incorporar dentro de su cultura de trabajo el
                            monitoreo de
                            salud y brindar a sus colaboradores la capacidad de contar con servicios de salud
                            preventivo,
                            mientras aumentan la capacidad de monitoreo, apoyando su estrategia de continuidad de
                            negocios.
                        </p>
                    </div>
                </Col>
            </Row>

            <Row style={{marginTop: '40px'}}>
                <Col xs={12} lg={6}>
                    <p style={{textAlign: 'center'}}>
                        <a href="https://www.sacpma.com/" target="_blank">
                            <Image alt={'logo'} src={sac} style={{width: '250px'}} fluid
                                   preview={false}/>
                        </a>
                    </p>
                </Col>
                <Col xs={12} lg={6}>
                    <p style={{textAlign: 'center'}}>
                        <a href="https://www.gbitcorp.com/" target="_blank">
                            <Image alt={'logo'} src={global} style={{width: '250px'}} fluid
                                   preview={false}/>
                        </a>
                    </p>
                </Col>
            </Row>

            <Row style={{marginTop: '100px'}}>
                <Col lg={{span: 6, offset: 3}} xs={12}>
                    <h4 className={"custom_subtitle"} style={{textAlign: 'center', margin: '0 0 50px 0'}}>Escríbenos y
                        dinos cómo podemos
                        ayudarte</h4>
                    <Form {...layout} form={form} name="nest-messages" onFinish={(values) => onFinish(values, form)}
                          validateMessages={validateMessages}>
                        <Form.Item name={'first_name'} label="Nombre" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'last_name'} label="Apellido" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'email'} label="Correo" rules={[{required: true, type: 'email'}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'message'} label="Mensaje" rules={[{required: true}]}>
                            <Input.TextArea/>
                        </Form.Item>

                        <div style={{width: '300px', margin: '20px auto'}}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={"6LfL92QaAAAAAMQqw58LMx6woMSN7WfpYZr8utkl"}
                                onChange={onChange}
                            />
                        </div>

                        <div style={{textAlign: "center"}}>
                            <p>
                                <Form.Item>
                                    <Button style={{borderColor: '#44d6a0', color: '#44d6a0'}} htmlType="submit"
                                            disabled={disabled}>
                                        Enviar
                                    </Button>
                                </Form.Item>
                            </p>
                        </div>
                    </Form>
                </Col>
            </Row>

        </Container>
    )
}

export default Section;
