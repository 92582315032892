import React, {Component} from "react";
import {Card, Image, Button} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import logo from "../../../../Assets/img/branding/prevengo.png";
import data from "../data/section1";
import history from "../../../../history";
import {createBrowserHistory} from "history";
import {Link} from 'react-router-dom';

const renderSection = () => {
    return data.map((item, idx) => {
        return (
            <Col sm={4}>
                <Card
                    cover={
                        <img
                            alt="example"
                            src={item.icon}
                            style={{width: '120px', margin: '0 auto 0 auto'}}
                        />
                    }
                    bordered={false}
                    style={{width: 300, margin: 'auto'}}
                >


                    <div className="custom-subtitle text-center">

                        <h5 style={{color: '#44d6a0'}}>{item.title}</h5>

                    </div>

                    <div className="custom-paragraph text-center">
                        <p className={"custom_text"}>{item.content}</p>
                    </div>


                        <Button className="custom-button-1"><Link to={item.url}>Conoce
                        más</Link></Button>

                </Card>
            </Col>
        )
    })
}

const Section1 = () => {
    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <div className="custom-title">
                        <h2 className="decorated" style={{color: '#44d7a0'}}>
                            <span>Con Prevengo podrás</span>
                        </h2>
                    </div>
                </Col>
            </Row>
            <Row>
                {renderSection()}
            </Row>
        </React.Fragment>

    )
}

export default Section1;
