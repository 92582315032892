import React, {Component, useEffect} from 'react'
import {Container} from "react-bootstrap";
import Section from "./components/Section";
import Overlay from "../../../Layout/OverlayContent";
import Navbar from "../../../Layout/Navbar";
import Footer from "../../../Layout/Footer";
import {NotificationContainer} from "react-light-notifications";
// import banner from "../../../assets/img/services/banner.jpg"
// import {Image} from "antd";
// import img_001 from "../../../assets/img/services/img_001.png";

const StethoscopeSection = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    });

    return (
        <React.Fragment>
            <Overlay/>
                <Navbar/>
            <div style={{width: '100%', backgroundColor: '#ffffff'}}>
                <Container>
                    <Section />
                </Container>
            </div>
            <Footer/>
            <NotificationContainer/>
        </React.Fragment>
    )

}

export default StethoscopeSection
