import React, {Component} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import Content from "./components/Content";
import SocialMedia from "./components/SocialMedia";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{width: '100%', backgroundColor: '#44d7a0'}}>
                    <Container style={{ marginTop: '80px'}}>
                        <Content />
                    </Container>
                </div>

                <div style={{width: '100%', backgroundColor: '#36c18d'}}>
                    <SocialMedia />
                </div>

            </React.Fragment>
        )
    }
}

export default Footer;
