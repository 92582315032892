import React, {Component} from 'react'
import {Container} from "react-bootstrap";
import Section from "./components/Section";
import Overlay from "../../Layout/OverlayContent";
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";
import {NotificationContainer} from "react-light-notifications";

const Contact = () => {
    return (
        <React.Fragment>
            <Overlay/>
                <Navbar/>
            <div style={{width: '100%', paddingTop: '75px', paddingBottom: '75px'}}>
                <Section/>
            </div>
            <Footer/>
            <NotificationContainer/>

        </React.Fragment>
    )
}

export default Contact
