import icon_001 from "../../../../Assets/img/home/icon_001.png";
import icon_002 from "../../../../Assets/img/home/icon_002.png";
import icon_003 from "../../../../Assets/img/home/icon_003.png";

const data = [
    {
        icon: icon_001,
        title: "Asistente Virtual",
        content: "El cual te dará diagnóstico presuntivo y  recomendaciones para un manejo preventivo de tu salud.",
        url: "/services"
    },

    {
        icon: icon_002,
        title: "Mediciones Especializadas",
        content: "Con el apoyo de dispositivos médicos el cual le dará seguridad a usted y a su doctor para manejar una atención y monitoreo remoto.",
        url: "/products"
    },

    {
        icon: icon_003,
        title: "Monitoreo Remoto",
        content: "Portal web para que el doctor pueda visualizara la distancia las mediciones y consultas realizadas. Todo con previa autorización del usuario.",
        url: "/services/doctors"
    }
]

export default data;
