import React, {Component} from "react";
import {Card, Image, Button, Timeline} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import {List, Avatar} from 'antd';
import img_001 from "../../../../../Assets/img/services/img_001.png"
import image_001 from "../../../../../Assets/img/products/img_002.jpg";
import image_002 from "../../../../../Assets/img/products/esthetoscope.png";

const Section = () => {

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>

                    <div className="custom-title">
                        <h2 className="decorated" style={{color: '#44d7a0'}}>
                            <span>Mediciones con el Estetoscopio Digital</span>
                        </h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className="custom_text_3">
                    <div className="custom-paragraph-2">
                    <p style={{fontSize: '18px'}}>
                        Este dispositivo cómodo, ligero y fácil de usar les permite a los usuarios poder realizarse
                        auscultaciones en diferentes sectores de su caja torácica.
                        El Estetoscopio Digital tiene la capacidad de funcionar bajo la modalidad de campana y la
                        modalidad de diafragma, según las necesidades que tenga el usuario.
                    </p>
                    <p style={{fontSize: '18px'}}>
                        Se recomienda la adquisición de este dispositivo en los siguientes casos de uso:
                        <ul>
                            <li>Personas con condiciones médicas de alto riesgo o con enfermedades/ condiciones
                                cardiovasculares y respiratorias y/o pulmonares. Su alcance permite realizar
                                auscultaciones
                                precisas en áreas intestinales, pero son mediciones poco comunes.
                            </li>
                            <li>
                                Personas mayores
                            </li>
                        </ul>
                    </p>
                    <p style={{fontSize: '18px'}}>
                        Su integración con la aplicación de Prevengo ha permitido detallarle al usuario los pasos
                        necesarios para poder realizarse una auscultación por su propia cuenta, al detallarle las zonas
                        donde debe colocarse el estetoscopio para obtener un resultado preciso. El alcance de las
                        mediciones con la aplicación detallan las secciones donde se realiza la auscultación en la zona
                        torácica, estas incluyen auscultaciones cardíacas y pulmonares frontales y traseras.
                    </p>

                    <p style={{fontSize: '18px'}}>
                        El usuario tendrá la capacidad de poder escuchar en todo momento los resultados de las
                        auscultaciones obtenidos con su Estetoscopio Digital, al tener acceso desde su Historial de
                        Mediciones. También podrá darle acceso a sus doctores y a los profesionales de la salud de los
                        que reciba atención para que desde nuestra Plataforma Web puedan revisar y escuchar los
                        resultados de las auscultaciones, interpretando de manera correcta los resultados obtenidos.
                        Se puede utilizar en dispositivos móviles con versión Android 4.3 o más nueva y iOS 6.1 o más
                        nueva. La conexión entre el Estetoscopio Digital y el dispositivo móvil se realiza desde la
                        aplicación móvil en la sección de Mediciones > Estetoscopio > Buscar Dispositivo, cliqueando el
                        botón “Conectar”. La conexión se ejecuta sincronizando ambos dispositivos mediante red
                        Bluetooth.
                        Los estetoscopios tienen un tiempo de entrega de 15 días hábiles desde recibido el pago.
                    </p>
                        </div>
                </Col>
                <Col xs={12} lg={6}>
                    <Col xs={12} lg={12}>
                        <Image
                        style={{width:'300px', margin:'50px 0 0 30%'}}
                        alt={'logo'}
                        src={image_001}
                        fluid
                        preview={false}
                    />
                    </Col>

                    <Col xs={12} lg={12}>
                        <Image
                        style={{width:'350px', margin:'70% 0 0 30%'}}
                        alt={'logo'}
                        src={image_002}
                        fluid
                        preview={false}
                    />
                    </Col>
                </Col>
            </Row>
        </React.Fragment>

    )

}

export default Section;
