import React, {Component} from 'react'
import {Col, Row, Container} from "react-bootstrap";
import Slider from "../../Pages/Home/components/Carousel";
import Section1 from "../../Pages/Home/components/Section1";
import Section2 from "../../Pages/Home/components/Section2";
import Section3 from "../../Pages/Home/components/Section3";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "../../Pages/Home";
import Services from "../../Pages/Services/GeneralSection";
import DoctorSection from "../../Pages/Services/DoctorSection";
import CompanySection from "../../Pages/Services/CompanySection";
import Products from "../../Pages/Products/GeneralSection";
import MonitorSection from "../../Pages/Products/MonitorSection";
import StethoscopeSection from "../../Pages/Products/StethoscopeSection";
import Contact from "../../Pages/ContactUs";
import Overlay from "../OverlayContent";
import Navbar from "../Navbar";
import Footer from "../Footer";
import {NotificationContainer} from "react-light-notifications";

// class Content extends Component {
//     render() {
//         return (
//             // <React.Fragment>
//             //     <div style={{width: '100%', backgroundColor: '#44d7a0'}}>
//             //         <Container style={{height: '400px', marginTop: '95px'}}>
//             //             <Slider/>
//             //         </Container>
//             //     </div>
//             //
//             //     <div style={{width: '100%', paddingTop:'75px', paddingBottom:'75px'}}>
//             //         <Container>
//             //             <Section1/>
//             //         </Container>
//             //     </div>
//             //
//             //     <div style={{width: '100%', paddingTop:'75px', paddingBottom:'75px', backgroundColor:'#f5f5f5'}}>
//             //         <Container>
//             //             <Section2/>
//             //         </Container>
//             //     </div>
//             //
//             //     <div style={{width: '100%', paddingTop:'75px', paddingBottom:'75px'}}>
//             //         <Container>
//             //             <Section3/>
//             //         </Container>
//             //     </div>
//             //
//             //
//             //
//             // </React.Fragment>
//         )
//     }
// }

const Content = () => {
    return (
        <React.Fragment>
            <div style={{marginTop: '95px'}}>
                <Route path='/' exact component={Home}/>
                <Route path='/services' exact component={Services}/>
                <Route path='/services/doctors' exact component={DoctorSection}/>
                <Route path='/services/companies' exact component={CompanySection}/>
                <Route path='/products' exact component={Products}/>
                <Route path='/products/monitor' exact component={MonitorSection}/>
                <Route path='/products/stethoscope' exact component={StethoscopeSection}/>
                <Route path='/contact' exact component={Contact}/>
            </div>
        </React.Fragment>
    )
}

export default Content;
