import React, {useState, useEffect} from "react";
import {AiFillBackward} from "react-icons/ai";

export const SampleNextArrow = (props) => {
    const {className, style, onClick, mobile} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block", right: mobile > 600 ? '5%' : "8%" }}
            onClick={onClick}
        />
    );
}

export const SamplePrevArrow = (props) => {
    const {className, style, onClick} = props;

    return (
        <div
            className={className}
            style={{...style, display: "block", left:'1%', zIndex:'1'}}
            onClick={onClick}
        />
    );
}
