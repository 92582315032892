import React, {Component} from "react";
import {Card, Image, Button} from "antd";
import {Col, Row, Container} from "react-bootstrap";
// import logo from "../../../assets/img/branding/prevengo.png";
// import image_001 from "../../../assets/img/home/home_section2_001.png";
import data from "../data/section3";
import {Link} from "react-router-dom";

class Section3 extends Component {

    renderSection = () => {
        return data.map((item, idx) => {
            return (
                <Col sm={4}>
                    <Card
                        cover={<img alt="example" src={item.icon}/>}
                        style={{width: 300, margin: 'auto'}}
                    >
                        <div className="custom-paragraph">
                            <p>{item.content}</p>
                        </div>


                        <Button className="custom-button-1">
                            <Link to={item.url}>
                                Conoce más
                            </Link>
                        </Button>


                    </Card>
                </Col>
            )
        })
    }

    render() {
        return (
            <Row>
                <Col sm={12}>
                    <div className="custom-title">
                        <h2 className="decorated" style={{color: '#44d7a0'}}>
                            <span>Enfoque comercial</span></h2>
                    </div>

                </Col>

                <Col sm={12}>
                    <div className="custom-paragraph-2">
                        <p>
                            Prevengo es una plataforma que brinda servicios para el manejo preventivo de la salud de
                            manera
                            accesible desde tu celular.
                        </p>
                    </div>

                </Col>

                {this.renderSection()}

            </Row>
        )
    }
}

export default Section3;
