import React, {Component} from "react";
import {Card, Image, Button, Collapse} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import data from "../data/section1";
import img_02 from '../../../../../Assets/img/services/img_002.png'

const {Panel} = Collapse;

const Section3 = () => {


    return (
        <Row>

            <Col sm={12}>
                <h2 className={'custom_subtitle'}>Mediciones con el celular</h2>
            </Col>
            <Col sm={8} className={"custom_text_3"}>

                <p>
                    Pueden ser solicitadas por el asistente virtual o realizadas directo por el usuario en la
                    sección mediciones. La aplicación ofrece la capacidad de medir la frecuencia respiratoria y
                    cardiaca sin dispositivos adicionales, solo con su smartphone. Esto permite recopilar
                    información para ser analizada y determinar variaciones significativas por el usuario.
                </p>
                <p>
                    Para realizar esta medición se debe contar con celular inteligente y colocar el dispositivo en
                    el pecho ejerciendo una leve presión por un periodo aproximado de 30 segundos.
                    En este periodo de tiempo nuestro algoritmo, en conjunto con los acelerómetros del celular,
                    realiza las mediciones y determina la calidad de esta.
                </p>
                <p>
                    Importante resaltar que la medición debe ser realizada cuando el individuo esté sereno y se debe
                    permanecer en silencio durante los 30 segundos.
                    Es recomendable que el celular no tenga cobertor para lograr una medición más precisa.
                </p>
                <p>
                    Una vez finalizada la medición la aplicación móvil le mostrará los resultados de la medición y
                    las recomendaciones a seguir. Si las mediciones son solicitadas como parte de una consulta, el
                    asistente continuará realizando las preguntas correspondientes hasta finalizar.
                </p>


            </Col>
            <Col sm={4}>
                <p style={{textAlign: 'right'}}>
                    <Image className={'custom_img_3'} alt={'logo'} src={img_02} fluid
                           preview={false}/>
                </p>

            </Col>

        </Row>
    )

}

export default Section3;
