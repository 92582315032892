import React, {Component} from "react";
import {Card, Image, Button, Timeline, List} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import data from "../../../Services/DoctorSection/data/section1";


const renderSection = () => {
    return data.map((item, idx) => {
        return (
            <Col xs={12} lg={4}>
                <Card
                    title={
                        <p style={{textAlign: 'center'}}>
                            <Image alt={'logo'} src={item.img} style={{width: '70px'}} fluid preview={false}/>
                        </p>
                    }
                    bordered={false}
                    style={{width: 300, margin: 'auto'}}
                >
                    <p style={{textAlign: 'center'}}>
                        <p className={"custom_text"}>{item.description}</p>
                    </p>

                </Card>
            </Col>
        )
    })
}

const Section1 = () => {

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <h2 className="custom_subtitle" style={{color: '#555555', marginTop: '50px', textAlign: 'left'}}>
                        <span>Doctores</span>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12}>
                    <h6 className="custom_text" style={{color: '#555555'}}>Con este servicio los doctores, centros médicos o clínicas
                        podrán</h6>
                </Col>
                <Col>

                    <List.Item>
                        <List.Item.Meta
                            title={<p className={"custom_text"}>Adquirir monitores para ofrecerlos a sus pacientes en estos esquemas:</p>}
                            description={
                                <div className={"custom_text_3"}>
                                    <p>Ofrecerlo como parte de su servicio de monitoreo entregándoselo para que lo
                                        utilice
                                        por el periodo de tiempo que el doctor considere. Con esto el usuario no tiene
                                        que
                                        realizar el gasto completo y el doctor evita costos de desplazamiento o atención
                                        innecesarios. Ejemplo: en paquetes de monitoreo por 15 días para pacientes COVID
                                        positivos, control post operatorio, etc.
                                    </p>

                                    <p>
                                        Venderlo a sus pacientes que requieren monitoreo y control periódico. Ejemplo:
                                        pacientes con cardiopatías, diabetes, condiciones del sistema inmune, personas
                                        mayores y/o con problemas de movilidad.
                                    </p>
                                </div>
                            }
                        />
                    </List.Item>

                    <List.Item>
                        <List.Item.Meta
                            title={ <p className={"custom_text"}>Visualizar los resultados de las mediciones en línea y a distancia por medio del portal web</p>}
                        />
                    </List.Item>

                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <h2 className="decorated custom_subtitle" style={{color: '#44d7a0'}}><span>Alcance del Servicio</span>
                    </h2>
                </Col>
            </Row>
            <Row>
                {renderSection()}
            </Row>
        </React.Fragment>

    )
}

export default Section1;
