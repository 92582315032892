import React, {Component} from "react";
import {Image} from "antd";
import logo from "../../../../Assets/img/branding/prevengo.png"
import {Navbar} from "react-bootstrap";

export class Logo extends Component {
    render() {
        return (
            <Navbar.Brand href="/" style={{backgroundColor: '#ffffff'}}>
                <Image alt={'logo'} src={logo} className={"custom-navbar-logo"} fluid preview={false}/>
            </Navbar.Brand>
        );
    }
}

export default Logo;
