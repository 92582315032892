import React, {Component} from "react";
import {Col, Row, Container} from "react-bootstrap";
import {Button, Image} from "antd";
import AppStore from "../../../../Assets/img/footer/AppStore.png";
import GooglePlayStore from "../../../../Assets/img/footer/GooglePlayStore.png"
import prevengo_vertical from "../../../../Assets/img/branding/prevengo_vertical.png"

class Content extends Component {


    render() {
        return (
            <Row style={{padding:'20px 0 0 0'}}>
                <Col xs={12} lg={4}>
                    <p style={{textAlign: 'center'}}>
                        <div style={{display:"table", margin:"0 auto", minHeight:"200px"}}>
                            <div style={{display:"table-cell", verticalAlign:"middle"}}>
                                <Image width={125}  src={prevengo_vertical} preview={false}/>
                            </div>
                        </div>
                    </p>
                </Col>
                <Col xs={12} lg={4}>
                    <p style={{textAlign: 'center'}}>
                        <h4 style={{color: 'white'}} className="custom-title">CONTÁCTENOS</h4>
                        <Row>
                            <Col sm={12}>
                                <div className="custom-paragraph-3">
                                <p style={{color: 'white'}} className={"custom_text"}>
                                    Ciudad de Panamá
                                    Obarrio, PH Kenex Plaza Piso 9
                                </p>
                                    </div>
                            </Col>

                            <Col sm={12}>
                                <div className="custom-paragraph-3">
                                <p style={{color: 'white'}}>
                                    (+507) 6282-8189
                                </p>
                                </div>
                            </Col>

                            <Col sm={12}>
                                <div className="custom-paragraph-3">
                                <p style={{color: 'white'}}>
                                    contacto@prevengoapp.com
                                </p>
                                </div>
                            </Col>

                        </Row>

                    </p>
                </Col>
                <Col xs={12} lg={4}>
                    <p style={{textAlign: 'center'}}>

                        <div style={{display:"table", margin:"0 auto", minHeight:"200px"}}>
                            <div style={{display:"table-cell", verticalAlign:"middle"}}>

                        <Col>
                            <a href='https://apps.apple.com/pa/app/prevengo/id1534943379' target="_blank">
                            <Image className={"custom_img_4"} alt={'AppStore'} src={AppStore} style={{width: '200px'}} fluid preview={false}/>
                            </a>
                            </Col>

                        <Col>
                            <a href='https://play.google.com/store/apps/details?id=com.prevengo' target="_blank">
                            <Image className={"custom_img_4"} alt={'GooglePlay'} src={GooglePlayStore} style={{width: '200px'}} fluid
                                   preview={false}/>
                            </a>
                        </Col>
                            </div>
                        </div>
                    </p>
                </Col>
            </Row>
        )
    }
}

export default Content;
