import React, {Component} from "react";
import {Card, Image, Button, Timeline, List, Divider} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import data from "../../../Services/DoctorSection/data/section1";


const renderSection = () => {
    return data.map((item, idx) => {
        return (
            <Col xs={12} lg={4}>
                <Card
                    title={
                        <p style={{textAlign: 'center'}}>
                            <Image alt={'logo'} src={item.img} style={{width: '80px'}} fluid preview={false}/>
                        </p>
                    }
                    bordered={false}
                    style={{width: 300, margin: 'auto'}}
                >
                    <p style={{textAlign: 'center'}}>
                        <p>{item.description}</p>
                    </p>

                </Card>
            </Col>
        )
    })
}

const Section1 = () => {

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <h2 className="custom_subtitle" style={{color: '#555555', marginTop: '50px', textAlign: 'left'}}>
                        <span>Empresas</span>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12} className={"custom_text_3"}>
                    <p style={{color: '#555555'}}>
                        El Covid-19 les deja a las empresas nuevas responsabilidades en relación con la incorporación de
                        controles de bioseguridad en sus instalaciones y el monitoreo de la salud de sus colaboradores
                        para la identificación temprana de casos.
                        Con este servicio las empresas podrán incorporar dentro de su cultura de trabajo el monitoreo de
                        salud y brindar a sus colaboradores la capacidad de contar con servicios de salud preventivo,
                        mientras aumentan la capacidad de monitoreo, apoyando su estrategia de continuidad de negocios.
                    </p>
                </Col>
            </Row>
            <Row style={{marginTop:'50px'}}>
                <Col xs={12} lg={1}></Col>
                <Col xs={12} lg={5}>
                    <h4 style={{textAlign:'center', color: '#555555'}} className={"custom_subtitle"}>Beneficios para las empresas</h4>
                    <Divider/>
                    <Timeline.Item color={"#555555"} className={"custom_text"}>Maneje de manera automatizada controles preventivos y de monitoreo del estado de salud de sus colaboradores, permitiendo manejar grandes volúmenes</Timeline.Item>
                    <Timeline.Item color={"#555555"} className={"custom_text"}>Evite el contacto para el manejo de estos controles</Timeline.Item>
                    <Timeline.Item color={"#555555"} className={"custom_text"}>Información en línea para análisis y atención por su personal médico o de recursos humanos</Timeline.Item>
                    <Timeline.Item color={"#555555"} className={"custom_text"}>Información histórica que facilita investigación</Timeline.Item>
                    <Timeline.Item color={"#555555"} className={"custom_text"}>Capacidad de brindar servicio de salud integral y digital</Timeline.Item>
                    <Timeline.Item color={"#555555"} className={"custom_text"}>Tecnología y servicio en mejora evolutiva, lo que le permite tercerizar esta gestión y enfocarse en su negocio</Timeline.Item>
                    <Timeline.Item color={"#555555"} className={"custom_text"}>Ambiente positivo con sus colaboradores al entregar herramientas para el cuidado de su salud</Timeline.Item>
                </Col>
                <Col xs={12} lg={5}>
                    <h4 style={{textAlign:'center', color: '#555555'}} className={"custom_subtitle"}>Beneficios para los colaboradores</h4>
                    <Divider/>
                    <Timeline>
                        <Timeline.Item color={"#555555"} className={"custom_text"}>Acceso a diferentes niveles de atención médica de manera digital</Timeline.Item>
                        <Timeline.Item color={"#555555"} className={"custom_text"}>Información histórica del estado de su salud</Timeline.Item>
                        <Timeline.Item color={"#555555"} className={"custom_text"}>Posibilidad de ampliar estos servicios a sus familiares</Timeline.Item>
                    </Timeline>
                </Col>
                <Col xs={12} lg={1}></Col>

            </Row>

        </React.Fragment>

    )
}

export default Section1;
