import img_001 from "../../../../Assets/img/home/img_001.png";
import img_002 from "../../../../Assets/img/home/img_002.png";
import img_003 from "../../../../Assets/img/home/img_003.png";

const data = [
    {
        icon: img_001,
        content: "Ofrece al usuario final la capacidad de monitorear el estado de su salud y de acceder a servicios especializados.",
        url: "/services"
    },

    {
        icon: img_002,
        content: "Ofrece a empresas la capacidad de monitorear el estado de salud de sus colaboradores, aumentando la productividad y disminuyendo la probabilidad de contagios por medio de detecciones tempranas.",
        url: "/services/companies"
    },

    {
        icon: img_003,
        content: "Permite a doctores y aliados comerciales ofrecer sus servicios a través de nuestra aplicación, trabajando en un entorno colaborativo entre empresas.",
        url: "/services/doctors"
    }
]

export default data;
