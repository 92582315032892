import React from 'react';
import {Card, Container, Image} from 'react-bootstrap';
import '../../../Assets/css/antd.css';
import complete from "../../../Assets/img/complete.png";
import '../../../Assets/css/fontCustom.css'


class TermsConditions extends React.Component {

    constructor(props) {
        super(props);
        this.toggleInviteUserModal = this.toggleInviteUserModal.bind(this);
        this.state = {
            showModal: false
        }
    }

    toggleInviteUserModal(toggle){
        this.setState({
            showModal: toggle
        })
    };


    render() {

        return (
            <Container fluid className={'BackgroundImageCustom'}>
                <Card style={{ 'margin': '0 auto',
                    'width': '80rem',
                    'max-width': '90%',
                    'margin-bottom': '2rem' }}>

                    <Card.Header className="text-center">
                        <Image alt={'logo'} src={complete} style={{width: '500px'}} fluid />
                        <Card.Title as="h3">TÉRMINOS Y CONDICIONES PARA EL USO DE LA APLICACIÓN MÓVIL PREVENGO</Card.Title>
                        <Card.Subtitle className={"mb-2 text-muted TextCustom"}>Última actualización: Mayo, 2020</Card.Subtitle>
                    </Card.Header>

                    <Card.Body className="text-justify">
                        <Card.Text className={'TextCustom'}>
                            Cualquier persona que desee acceder a la APLICACIÓN móvil Prevengo, podrá hacerlo sujetándose a los Términos y Condiciones Generales.
                            Dado lo anterior, toda persona que accede a la APLICACIÓN móvil Prevengo declara bajo la gravedad de juramento que ha leído y entendido los Términos y Condiciones Generales, y en consecuencia se obliga a cumplir y respetar lo aquí establecido.
                            Prevengo se reserva el derecho a modificar estos Términos y Condiciones Generales en cualquier momento, los cuales serán actualizados en la APLICACIÓN y constituirá en su acuerdo a dichas modificaciones.
                            CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA APLICACIÓN MOVIL.
                            A continuación, se describen los términos y condiciones generales (los "Términos y Condiciones Generales") aplicables a la APLICACIÓN móvil denominada Prevengo de propiedad de las empresas Global Business IT Corp. y Servicios Avanzados de Consultoría, S.A.
                        </Card.Text>

                        <Card.Title as="h3">IDENTIFICACIÓN DE LAS PARTES</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Los términos "Nosotros", "Nuestros" y “Grupo” se refiere a Global Business IT Corp y Servicios Avanzados de Consultorías, sociedades legalmente constituidas conforme a las leyes de la República de Panamá, de naturaleza comercial, domiciliada en la ciudad de Panamá, las cuales se han unido en colaboración y comparten la propiedad de la APLICACIÓN, y;
                            Los términos Usuario, se refiere a la persona natural, que pretende acceder a APLICACIÓN, contenido y servicios ofrecidos a través de la APLICACIÓN móvil Prevengo.
                            Los anteriores se identificarán conjuntamente como LAS PARTES.
                        </Card.Text>

                        <Card.Title as="h3">PROPIEDAD INTELECTUAL</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Todas las marcas, enseñas, logos, nombres y cualesquiera otros signos distintivos, así como los modelos de utilidad y/o diseños industriales y demás elementos de propiedad industrial o intelectual insertados, usados y/o desplegados en la APLICACIÓN móvil son Propiedad exclusiva del GRUPO. Nada podrá ser desarrollado como concesión u otorgamiento a cualquier título de autorizaciones, licencias o cualquier otro derecho para usar o disponer de cualquier forma de la Propiedad Industrial, sin el permiso por escrito del GRUPO o del titular de los derechos de la misma. Cualquier uso no autorizado constituirá una violación a los presentes Términos y Condiciones y a las normas vigentes nacionales e internacionales sobre Propiedad Industrial.
                        </Card.Text>

                        <Card.Title as="h3">DERECHOS DE AUTOR</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Todo el material informático, gráfico, publicitario, fotográfico, de multimedia, audiovisual y/o de diseño, así como todos los contenidos, textos y bases de datos puestos a su disposición en las redes sociales y en esta APLICACIÓN móvil son de propiedad exclusiva de del GRUPO. Todos los Contenidos están protegidos por las normas sobre Derechos de Autor y por todas las normas nacionales e internacionales que le sean aplicables. Exceptuando lo expresamente estipulado en estos Términos y Condiciones Generales, queda prohibido todo acto de copia, reproducción, modificación, creación de trabajos derivados, venta o distribución, exhibición de los Contenidos, de ninguna manera o por ningún medio, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo por escrito del GRUPO. En ningún caso estos Términos y Condiciones Generales confieren derechos, licencias y/o autorizaciones para realizar los actos anteriormente descritos. Cualquier uso no autorizado de los Contenidos constituirá una violación a los presentes Términos y Condiciones Generales y a las normas vigentes sobre derechos de autor.
                        </Card.Text>

                        <Card.Title as="h3">REGISTRO O AFILIACIÓN.</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Para utilizar la APLICACIÓN es requisito indispensable registrarse, identificarse y presentarse como Usuario en la APLICACIÓN móvil, actividades que se realizan por medio de una cuenta del cliente, la cual es de carácter personal, intransferible y de responsabilidad exclusiva y excluyente del cliente.
                            <br/> El Usuario acepta que una vez ha finalizado con el proceso de registro por la APLICACIÓN móvil, queda inmediatamente perfeccionado generando las obligaciones respectivas para cada una de las partes.
                            <br/>A su exclusiva discreción, el GRUPO podrá requerir una inscripción adicional a los Usuarios que hagan parte de campañas o promociones especiales. Estas actividades se publicarán bajo la modalidad que el GRUPO habilite para este tipo de Usuarios.
                        </Card.Text>

                        <Card.Title as="h3">DERECHO DE ACCESO</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Por medio de estos Términos y Condiciones se le otorga al USUARIO el derecho de uso limitado, no exclusivo y no transferible a la APLICACIÓN y al uso de los servicios que se promueven en esta, para uso personal y no comercial. Nos reservamos el derecho a nuestra entera discreción de negar o suspender el acceso y el uso de la APLICACIÓN o sus SERVICIOS por cualquier razón. El USUARIO acepta que de ninguna manera: a) Se hará pasar por otra persona, b) Es menor de edad, c) usará la APLICACIÓN y los SERVICIOS para violar ninguna ley ni regulación local, nacional o internacional; d) desmantelar, descompilar, decodificar, copiar todo o en partes, aplicar ingeniería en reversa, transferir, traducir cualquier software o componente parte de la APLICACIÓN; e) distribuir virus o cualquier otro código que pueda dañar equipos tecnológicos; f) usar los SERVICIOS de la APLICACIÓN de cualquier manera que rebase el objetivo y el alcance plasmado en este documento; g) adicionalmente, el USUARIO acepta a no utilizar ningún tipo de lenguaje ofensivo y mantener siempre un comportamiento cordial.
                            <br/>El USUARIO tiene derecho en cualquier momento y por cualquier razón a desactivar su CUENTA DE USUARIO mandando un correo a prevengo@gbitcorp.com.
                        </Card.Text>

                        <Card.Title as="h3">USO Y OBJETIVO DE PREVENGO</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Prevengo es una APLICACIÓN móvil que facilita al USUARIO el cuidado de su salud de manera preventiva mediante el monitoreo de sus signos vitales y la presentación de recomendaciones generales, en ninguna circunstancia se entenderá que el objetivo es brindar un diagnóstico o consulta médico. Nosotros ponemos a su disposición una APLICACIÓN que, entre otras cosas, busca: a) Brindar una herramienta para la medición de frecuencia cardiaca y respiratoria sin la necesidad de adquirir dispositivos externos adicionales, b) Recopilar información de su condición para presentar recomendaciones generales para el manejo preventivo de su salud, c) Analizar su información y cruzarla con la de otras personas para analizar tendencias o patrones sociodemográficos, d) Brindar servicios de atención ofrecidos por terceros. Dichos servicios médicos, en su caso, serán prestados de forma absolutamente independiente a Prevengo.
                            <br/>Las recomendaciones que recibe el USUARIO por medio de la APLICACIÓN, no intentan reemplazar la atención primaria de un médico o ser un médico permanente en casa. Estas recomendaciones podrían apoyar o complementar la atención de un PROFESIONAL DE LA SALUD, de cuál es la mejor vía para que pueda ser diagnosticado y tratado el USUARIO.
                            <br/>Aceptando estos Términos de Uso, el USUARIO confirma que entiende y acepta que puede esperar beneficios a su salud como consecuencia del uso de la APLICACIÓN, pero que ningún resultado puede ser garantizado o asegurado
                        </Card.Text>

                        <Card.Title as="h3">CONTENIDO</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Ninguna información contenida en la APLICACIÓN debe considerarse como consulta médica o como garantía de que un tratamiento es apropiado o efectivo para el USUARIO. Así mismo, ninguna información otorgada por los proveedores de la salud será considerada como contenido de la APLICACIÓN o del GRUPO, se entenderá información totalmente independiente y exclusiva del proveedor de la salud. La información contenida en Prevengo, será entendida como meramente informativa y no podrá ser considerada como consulta médica.
                            <br/>El GRUPO así como cualquier persona relacionada y/o afiliada a este, incluyendo, sin limitar, directores, apoderados, representantes, administradores, empleados, accionistas y/o agentes, presentes o anteriores, no serán responsables de errores u omisiones en los contenidos la APLICACIÓN. Asimismo, no serán responsables, bajo ningún caso o circunstancia, por datos y/o perjuicios que se pudieren causar a USUARIOS derivado del uso de esta.
                            <br/>El contenido de la APLICACIÓN móvil, bases de datos, redes y archivos que permiten al Usuario acceder y usar su Cuenta, son de propiedad del GRUPO, y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y/o la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito del GRUPO.
                        </Card.Text>

                        <Card.Title as="h3">SIGNOS DISTINTIVOS</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Los signos distintivos de Prevengp y cualquier otro exhibido en esta APLICACIÓN móvil, es de titularidad de del GRUPO. Bajo ningún supuesto se podrá considerar que se permite el uso o se está concediendo una licencia cualquiera que sea su carácter, sobre las marcas, nombres comerciales, enseñas o lemas. Por lo tanto, se considera expresamente prohibido el uso de los mismos, so pena de verse incurso en las responsabilidades civiles y penales a que haya lugar.
                        </Card.Text>

                        <Card.Title as="h3">ENLACES EXTERNOS</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            La APLICACIÓN móvil puede contener enlaces a otros sitios web o aplicaciones, lo cual no indica que sean propiedad u operados por el GRUPO. En virtud que el GRUPO no tiene control sobre tales sitios o aplicaciones, NO será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente. La presencia de enlaces a otros sitios web o aplicaciones no implica una sociedad, relación, aprobación, respaldo del GRUPO a dichos sitios y sus contenidos.
                        </Card.Text>

                        <Card.Title as="h3">INFORMACIÓN DE MEDICIONES Y CONDICIONES MÉDICAS</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            El USUARIO acepta que toda la información recopilada y generada por medio del uso de la APLICACIÓN es información veraz y precisa, y que la misma pueda ser utilizada para realizar análisis que permitan al GRUPO identificar mejoras la APLICACIÓN y en las recomendaciones proporcionadas. Adicional, el USUARIO autoriza al GRUPO a compartir esta información con entidades gubernamentales u otras empresas que trabajen en colaboración con el GRUPO para brindar el servicio, o para mejorar y evolucionar los servicios ofrecidos por medio de la APLICACIÓN. La información compartida será manejada con la confidencialidad que establecen las leyes y las mejores prácticas, su uso será exclusivamente para el análisis y mejora de la APLICACIÓN o para el otorgamiento en tiempo y forma de los servicios. Bajo ninguna circunstancia, el GRUPO venderá o utilizará su información para fines diferentes a los establecidos con el objetivo de la APLICACIÓN.
                        </Card.Text>

                        <Card.Title as="h3">EXENCIÓN DE RESPONSABILIDAD</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            El GRUPO procurará ejecutar los aspectos técnicos, administrativos y operativos relacionados con la APLICACIÓN móvil de forma diligente y utilizando para ello personal capacitado, no obstante lo anterior, el GRUPO queda exonerado de toda responsabilidad derivada de situaciones de caso fortuito o fuerza mayor, lo que incluye, entre otras hipótesis, actos de terrorismo, sabotajes, incluyendo ataques informáticos, motines, huelgas, decisiones de autoridad soberana, actos o hechos de terceros, guerras exteriores o interiores, terremotos, maremotos, inundaciones, vendavales, tornados, huracanes y, en general, de todas las situaciones imprevistas, imprevisibles o irresistibles análogas a las anteriores que puedan afectar directa o indirectamente el normal funcionamiento de la APLICACIÓN móvil.
                            <br/>Se entienden justificadas la suspensión de la disponibilidad de la APLICACIÓN móvil originada en situaciones normales de la operación del GRUPO, tales como, pero sin limitarse a ellos, las derivadas del mantenimiento, programación, pruebas programadas, aprovisionamiento o readecuación de los equipos o inconvenientes de cualquier tipo en los equipos relevantes. Cuando los eventos anteriormente descritos sean previsibles, el GRUPO deberá notificar la suspensión del servicio a través de su APLICACIÓN móvil, con la antelación que considere necesaria.
                            <br/>Dado lo anterior, el GRUPO no se responsabiliza bajo ninguna circunstancia por los daños, perjuicios o pérdidas de la información del Usuario y demás, causados por fallas en sus sistema o servidores.
                        </Card.Text>

                        <Card.Title as="h3">INVALIDEZ DE LAS DISPOSICIONES</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            En caso de que una o más de las disposiciones contenidas en los Términos y Condiciones Generales sean consideradas nulas, ilegales o ineficaces en cualquier aspecto, la validez, legalidad, exigibilidad o eficacia del resto de las disposiciones aquí contenidas no se verán afectadas o anuladas por dicha circunstancia.
                        </Card.Text>

                        <Card.Title as="h3">DESCARGO DE RESPONSABILIDAD</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            El GRUPO no se responsabiliza por la interpretación, análisis y comprensión del contenido publicado en esta APLICACIÓN móvil. El acceso se provee a nuestros usuarios como se presenta y de acuerdo a nuestra disponibilidad sin garantía de ninguna clase a menos que ya esté previamente expresada o implícita.
                            <br/> El GRUPO maneja la APLICACIÓN móvil desde Panamá. Los términos de esta APLICACIÓN se rigen por las leyes de Panamá.
                            <br/>En ningún caso el GRUPO será responsable por los daños o consecuencias directas, incidentales o indirectas que el uso de la APLICACIÓN móvil o alguno de sus links o enlaces pueda causar.
                        </Card.Text>

                        <Card.Title as="h3">TERMINOS DE COMPRA, PAGO DE SERVICIOS Y REEMBOLSOS</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            El USUARIO acepta y accede a pagar todos los cargos hechos a su CUENTA DE USUARIO, de acuerdo con las tarifas y términos de pago establecidas en el momento que EL SERVICIO, es generado u adquirido. Al capturar la información de algún MEDIO DE PAGO, autorizas a el GRUPO o cualquiera de sus afiliadas, a cargar inmediatamente tu medio de pago y CUENTA DE USUARIO, con las tarifas correspondientes a los servicios solicitados a través de la plataforma. En adelante no es necesaria ninguna autorización adicional para poder realizar los cargos a los medios de pago capturados en la plataforma.
                            <br/>El GRUPO se reserva el derecho a modificar o implementar una nueva estructura de precios por LOS SERVICIOS que ofrece la plataforma en cualquier momento. Cualquier cambio a la estructura de precios será comunicado a través de la APLICACIÓN y los medios de comunicación que se dispongan.
                            <br/>Los pagos hechos por LOS SERVICIOS son finales y no reembolsables, a menos que se determine lo contrario por parte del GRUPO analizando caso por caso mediante una solicitud por escrito enviada por el USUARIO al correo prevengo@gbitcorp.com. El GRUPO responderá a cualquier solicitud de un USUARIO en un plazo no mayor de 30 días naturales.
                            <br/>El GRUPO, puede generar ofertas promocionales y descuentos que pueden modificar los cargos.
                        </Card.Text>

                        <Card.Title as="h3">LEGISLACIÓN Y JURISDICCIÓN APLICABLE</Card.Title>
                        <Card.Text className={'TextCustom'}>
                            Estos Términos y Condiciones están sujetos a las leyes gobernables de Panamá. Si alguna provisión de estos Términos y Condiciones se encuentra invalidada por alguna corte competente, con jurisdicción competente, la invalidez de dicha provisión no afectará la validez del restante de las provisiones de los Términos y Condiciones de Uso, las cuales se mantendrán en completa fuerza y efecto.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>

                        &copy; <a href="https://www.gbitcorp.com/" style={{'textDecoration': 'none', 'color': 'royalblue'}}>Global Business IT CORP</a> & <a href="https://www.sacpma.com//" style={{'textDecoration': 'none', 'color': 'royalblue'}}>SAC</a>

                    </Card.Footer>
                </Card>
            </Container>

        );
    }
}

export default (TermsConditions);
