import React, {Component} from "react";
import {Col, Row, Container} from "react-bootstrap";
import {Button} from "antd";
import {LinkedinOutlined, FacebookOutlined, InstagramOutlined, YoutubeOutlined} from "@ant-design/icons";
import {FaFacebookF, FaLinkedinIn, FaInstagram, FaYoutube, FaMapMarkerAlt} from "react-icons/fa"
import {SiWhatsapp} from "react-icons/si"

class SocialMedia extends Component {


    render() {
        return (
            <Container>
                <Row style={{padding:'25px 0 25px 0'}}>
                    {/*<Col>*/}
                    {/*    <a style={{textAlign: 'center'}}>*/}
                    {/*        <Button type="primary" size={'large'} style={{*/}
                    {/*            backgroundColor: 'rgba(255,255,255,0)',*/}
                    {/*            borderColor: 'white',*/}
                    {/*            borderWidth: '2px'*/}
                    {/*        }} shape="circle"*/}
                    {/*                icon={<FaFacebookF style={{fontSize: '24px', marginTop: '-5px', color: 'white'}}/>}/>*/}
                    {/*    </a>*/}
                    {/*</Col>*/}
                    <Col>
                        <a href={"https://www.instagram.com/prevengoapp/"} style={{textAlign: 'center'}}>
                            <p>
                                <Button type="primary" size={'large'} style={{
                                    backgroundColor: 'rgba(255,255,255,0)',
                                    borderColor: 'white',
                                    borderWidth: '2px'
                                }} shape="circle"
                                        icon={<FaInstagram
                                            style={{fontSize: '24px', marginTop: '-5px', color: 'white'}}/>}/>
                            </p>
                        </a>
                    </Col>
                    <Col>
                        <a style={{textAlign: 'center'}}>
                            <p>
                                <Button type="primary" size={'large'} style={{
                                    backgroundColor: 'rgba(255,255,255,0)',
                                    borderColor: 'white',
                                    borderWidth: '2px'
                                }} shape="circle"
                                        icon={<FaLinkedinIn
                                            style={{fontSize: '24px', marginTop: '-5px', color: 'white'}}/>}/>
                            </p>
                        </a>
                    </Col>
                    <Col>
                        <a href={"https://www.youtube.com/channel/UCo9KP82UXPCqeuvq8E2wpDA"}
                           style={{textAlign: 'center'}}>
                            <p>
                                <Button type="primary" size={'large'} style={{
                                    backgroundColor: 'rgba(255,255,255,0)',
                                    borderColor: 'white',
                                    borderWidth: '2px'
                                }} shape="circle"
                                        icon={<FaYoutube
                                            style={{fontSize: '24px', marginTop: '-5px', color: 'white'}}/>}/>
                            </p>
                        </a>

                    </Col>
                </Row>
            </Container>

        )
    }
}

export default SocialMedia;
