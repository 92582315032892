import React, {Component} from "react";
import {Card, Image, Button, Timeline} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import {List, Avatar} from 'antd';
import data from "../data/section1";
import chatbot from "../../../../../Assets/img/services/chatboot.png"


const Section2 = () => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className="decorated custom_subtitle" style={{color: '#44d7a0'}}><span>Asistente virtual</span>
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col lg={{span: 4, offset: 2}}>
                    <div className='wrapperLeft'>
                        <div className='speechbubble'>
                            <p className={"custom_text_chatbot"}>
                                Al ingresar a la opción de Consulta se iniciará una conversación con nuestro asistente
                                virtual, el cual le pedirá al usuario realizarse mediciones y contestar preguntas (en su
                                gran mayoría cerradas) para obtener un diagnóstico presuntivo y dar recomendaciones al
                                usuario. Dependiendo de este diagnóstico se establece el plan de monitoreo a seguir.
                            </p>
                        </div>
                    </div>

                </Col>
            </Row>

            <Row style={{margin: '-70px 0 20px 0'}}>
                <Col>
                    <p style={{textAlign: 'center'}}>
                        <Image alt={'logo'} className={"custom_img_2"} src={chatbot} style={{width: '200px'}} fluid
                               preview={false}/>
                    </p>

                </Col>
            </Row>

            <Row>
                <Col lg={{span: 4, offset: 6}}>
                    <div className='wrapperRight'>
                        <div className='speechbubble'>
                            <p className={"custom_text_chatbot"}>
                                Adicional, en el caso que el usuario sienta algún malestar o señal de alerta podrá
                                iniciar una consulta con el asistente virtual en cualquier momento. El contar con esta
                                facilidad le garantiza poder identificar a cualquier hora y cualquier día de la semana
                                alguna señal de alerta.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>


        </React.Fragment>

    )

}

export default Section2;
