import React, {Component, useEffect} from 'react'
import {Container} from "react-bootstrap";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Overlay from "../../../Layout/OverlayContent";
import Navbar from "../../../Layout/Navbar";
import Footer from "../../../Layout/Footer";
import {NotificationContainer} from "react-light-notifications";
// import banner from "../../../assets/img/services/banner.jpg"
// import {Image} from "antd";
// import img_001 from "../../../assets/img/services/img_001.png";

const GeneralSection = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    });

    return (
        <React.Fragment>
            <Overlay/>
                <Navbar/>
            <div style={{width: '100%', backgroundColor: '#276696'}}>
                <Container>
                    <Section1 />
                </Container>
            </div>

            <div style={{width: '100%'}}>
                <Container>
                    <Section2 />
                </Container>
            </div>

            <div style={{width: '100%', paddingTop: '75px', paddingBottom: '75px', backgroundColor: '#f5f5f5'}}>
                <Container>
                    <Section3 />
                </Container>
            </div>

            <div style={{width: '100%'}}>
                <Container>
                    <Section4 />
                </Container>
            </div>
            <Footer/>
            <NotificationContainer/>

        </React.Fragment>
    )

}

export default GeneralSection
