import React, {Component} from 'react'
// import {Carousel, Image} from "antd";
import Slider from "react-slick";
import banner1 from "../../../../Assets/img/carousel/banner1.jpg"
import banner2 from "../../../../Assets/img/carousel/banner2.png"
import banner3 from "../../../../Assets/img/carousel/banner3.png"
import banner4 from "../../../../Assets/img/carousel/banner4.png"
import banner5 from "../../../../Assets/img/carousel/banner5.png"

import banner1_min from "../../../../Assets/img/carousel/Carrusel1.png"
import banner2_min from "../../../../Assets/img/carousel/Carrusel2.png"
import banner3_min from "../../../../Assets/img/carousel/Carrusel3.png"
import banner4_min from "../../../../Assets/img/carousel/Carrusel4.png"
import banner5_min from "../../../../Assets/img/carousel/Carrusel5.png"

import {Link} from "react-router-dom";
import {AiOutlineLeft} from "react-icons/ai";
import {SampleNextArrow, SamplePrevArrow} from "./CustomCarouselArrows";

//import logo from "../../../assets/img/branding/prevengo.png";
const desktop_size = [banner1, banner2, banner3, banner4, banner5]
const mobile_size = [banner1_min, banner2_min, banner3_min, banner4_min, banner5_min]

class Carousel extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.carousel = React.createRef();
        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
            currentBG: 0,
            banner: desktop_size
        }
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    }

    next() {
        this.carousel.next();
    }

    previous() {
        this.carousel.prev();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }


    render() {

        const settings = {
            dots: false,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow mobile={this.state.width}/>,
            prevArrow: <SamplePrevArrow />
        };


        const baseStyle = {
            height: '400px',
            color: '#fff',
            lineHeight: '160px',
            backgroundImage: `url(${this.state.banner[0]})`,
            textAlign: 'center',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
        }

        const nextBanner = () => {
            let new_array = [...this.state.banner];
            new_array.push(new_array.shift());
            this.setState({
                banner: new_array
            });
        }

        return (
            <React.Fragment>
                <Slider {...settings}>
                    <div>
                        <div className='slider__image' style={{
                            width: '100%',
                            minHeight: '400px',
                            backgroundSize: '100% 100%',
                            'backgroundImage': `url(${this.state.width > 600 ? banner1 : banner1_min})`
                        }}>
                            <a href='https://apps.apple.com/pa/app/prevengo/id1534943379' target="_blank"
                               style={{width: '250px', height: '80px', float: 'left', margin: '17% 0% 0 27%'}}>
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.prevengo' target="_blank"
                               style={{width: '250px', height: '80px', float: 'right', margin: '17% 25% 0 0'}}>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className='slider__image' style={{
                            width: '100%',
                            minHeight: '400px',
                            backgroundSize: '100% 100%',
                            'backgroundImage': `url(${this.state.width > 600 ? banner2 : banner2_min})`
                        }}>
                            <Link to={'/services'} style={{width: '100%', minHeight: '400px', display: 'block'}}/>
                        </div>
                    </div>
                    <div>
                        <div className='slider__image' style={{
                            width: '100%',
                            minHeight: '400px',
                            backgroundSize: '100% 100%',
                            'backgroundImage': `url(${this.state.width > 600 ? banner3 : banner3_min})`
                        }}>
                            <Link to={'/services/doctors'}
                                  style={{width: '100%', minHeight: '400px', display: 'block'}}/>
                        </div>
                    </div>
                    <div>
                        <div className='slider__image' style={{
                            width: '100%',
                            minHeight: '400px',
                            backgroundSize: '100% 100%',
                            'backgroundImage': `url(${this.state.width > 600 ? banner4 : banner4_min})`
                        }}>
                            <Link to={'/products/monitor'}
                                  style={{width: '100%', minHeight: '400px', display: 'block'}}/>
                        </div>
                    </div>
                    <div>
                        <div className='slider__image' style={{
                            width: '100%',
                            minHeight: '400px',
                            backgroundSize: '100% 100%',
                            'backgroundImage': `url(${this.state.width > 600 ? banner5 : banner5_min})`
                        }}>
                            <Link to={'/products/stethoscope'}
                                  style={{width: '100%', minHeight: '400px', display: 'block'}}/>
                        </div>
                    </div>
                </Slider>

                {/*<Carousel autoplay beforeChange={nextBanner} dots={false} arrows={true}>*/}
                {/*    <div>*/}
                {/*        <div style={baseStyle}>*/}
                {/*            <a href='https://play.google.com/store/apps/details?id=com.prevengo'*/}
                {/*               style={{width: '250px', height: '80px', float: 'right', margin: '17% 26% 0 0'}}>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <div style={baseStyle}>*/}
                {/*            <Link to={'/services'} style={{width: '100%', height: '100%', display: 'block'}}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <div style={baseStyle}>*/}
                {/*            <Link to={'/services/doctors'} style={{width: '100%', height: '100%', display: 'block'}}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <div style={baseStyle}>*/}
                {/*            <Link to={'/products/monitor'} style={{width: '100%', height: '100%', display: 'block'}}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <div style={baseStyle}>*/}
                {/*            <Link to={'/products/monitor'} style={{width: '100%', height: '100%', display: 'block'}}/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Carousel>*/}
            </React.Fragment>
        )
    }
}

export default Carousel;
