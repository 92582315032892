import React, {Component} from "react";
import {Card, Image, Button, Timeline} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import {List, Avatar} from 'antd';
import img_001 from "../../../../../Assets/img/services/img_001.png"
import image_001 from "../../../../../Assets/img/products/img_001.jpeg";


const Section = () => {

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <div className="custom-title">
                        <h2 className="decorated" style={{color: '#44d7a0'}}>
                            <span>Mediciones con el MONITOR TODO EN UNO</span>
                        </h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <Col>
                        <Image
                            style={{margin:'50px 0 0 30%'}}
                            alt={'logo'}
                            src={image_001}
                            fluid
                            preview={false}
                        />
                    </Col>
                    <Col>
                        <a href='https://cuanto.app/prevengoapp/p/07ed9c?ltclid=2b473fd4-10e2-4cce-84f6-2ea53fc51460' target="_blank">
                            <Button
                                size={"large"}
                                style={{margin:'30px 0 30px 25%', borderColor: '#44d7a0', color: '#44d7a0'}}>COMPRAR
                            </Button>
                        </a>
                    </Col>
                </Col>
                <Col xs={12} lg={6} className="custom_text_3">
                    <div className="custom-paragraph-2">


                    <p style={{fontSize: '18px', textAlign: 'right'}}>
                        El 90% de las consultas realizadas a doctores pueden ser atendidas por medio digital y del 10%
                        restante solo el 2% requiere atención física o traslado si se cuenta con mediciones
                        especializadas.
                        Para este servicio es requerido contar con el Monitor de Salud TODO EN UNO (Dispositivo
                        comercializado por Prevengo) y que ya está previamente integrado a la aplicación.
                        Con este sofisticado, portátil y práctico dispositivo podrá medirse: la presión arterial,
                        saturación de oxígeno, frecuencia cardíaca, Temperatura, ECG y glucosa en sangre, siendo un
                        insumo fundamental para realizar una atención y monitoreo 100% digital y virtual, sin la
                        necesidad de transportarse a hospitales o consultorios médicos.
                    </p>

                        </div>
                </Col>
            </Row>
        </React.Fragment>

    )

}

export default Section;
