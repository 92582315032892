import React, {Component} from "react";
import {Card, Image, Button, Timeline} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import {List, Avatar} from 'antd';
import img_001 from "../../../../../Assets/img/products/img_001.jpeg"
import img_002 from "../../../../../Assets/img/products/img_002.jpg"

const Section = () => {

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <h2 className="custom_subtitle"
                        style={{marginTop: '50px', textAlign: 'center'}}>
                        <span>Mediciones Especializadas</span>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12} className="custom_text_3">
                    <p>
                        Contamos con dispositivos de salud integrados a la plataforma, lo que le permite contar con
                        mediciones especializas para su consumo. Podrá autorizar a su doctor de cabecera para que
                        visualice la información a través de la plataforma web.
                    </p>
                </Col>
            </Row>
            <Row style={{marginTop: '70px'}}>
                <Col xs={12} lg={6}>
                    <Card
                        cover={<img alt="example" src={img_001} style={{width:'200px', margin: '0 auto 0 auto'}}/>}
                        style={{
                            width: 300,
                            margin: 'auto',
                            backgroundColor: 'rgba(255,255,255,0)',
                            borderColor: 'rgba(255,255,255,0)'
                        }}
                    >
                        <p style={{textAlign: 'center'}}>
                            <h5 className="custom_text" style={{textAlign: 'center'}}>
                                <span>Monitor TODO en UNO</span>
                            </h5>
                            <Button style={{borderColor: '#44d6a0', color: '#44d6a0'}}>Conoce más</Button>
                        </p>

                    </Card>
                </Col>
                <Col xs={12} lg={6}>
                    <Card
                        cover={<img alt="example" src={img_002} style={{width:'200px', margin: '0 auto 0 auto'}}/>}
                        style={{
                            width: 300,
                            margin: 'auto',
                            backgroundColor: 'rgba(255,255,255,0)',
                            borderColor: 'rgba(255,255,255,0)'
                        }}
                    >
                        <p style={{textAlign: 'center'}}>
                            <h5 className="custom_text" style={{textAlign: 'center'}}>
                                <span>Estetoscopio Digital</span>
                            </h5>
                            <Button style={{borderColor: '#44d6a0', color: '#44d6a0'}}>Conoce más</Button>
                        </p>

                    </Card>
                </Col>
            </Row>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <h2 className="custom_subtitle" style={{color: '#ffffff'}}><span>Se recomienda la adquisición de este dispositivo en los siguientes casos de uso</span>*/}
            {/*        </h2>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row>
                <Col xs={12} lg={4}>

                </Col>
            </Row>
        </React.Fragment>

    )

}

export default Section;
