import React, {useState} from "react";
import {Navbar, Nav, NavDropdown, Container} from "react-bootstrap";
import {Button, Divider} from "antd";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../../../../Assets/img/branding/prevengo.png";
import {Link, Redirect} from 'react-router-dom';
import {RiArrowDropDownLine} from 'react-icons/ri';
import history from "../../../../history";


const NavigationBar = () => {

    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [collapse, setCollapse] = useState(true);

    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);

    const showDropdown1 = (e) => {
        setShow1(!show1);
    }
    const hideDropdown1 = e => {
        setShow1(false);
    }

    const showDropdown2 = (e) => {
        setShow2(!show2);
    }
    const hideDropdown2 = e => {
        setShow2(false);
    }

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    const onDropdownSelect = () => {
        setCollapse(false);
    }

    return (
        <React.Fragment>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className={"custom-navbar"}/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto custom-nav-items">


                    <Nav.Link href="/">
                        Inicio
                    </Nav.Link>

                    <hr className="custom-divider"/>

                    {/*<Divider/>*/}


                    <NavDropdown title={<Link to={"/services"}>Servicios</Link>} id="basic-nav-dropdown"
                                 show={show1}
                                 onMouseEnter={showDropdown1}
                                 onMouseLeave={hideDropdown1}>
                        {/*<NavDropdown.Item href="">Doctores</NavDropdown.Item>*/}
                        <NavDropdown.Item href="/services/doctors">
                            Doctores
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/services/companies">
                            Empresas
                        </NavDropdown.Item>
                    </NavDropdown>


                    {/*<NavDropdown title={<Nav.Link href="/services">Servicios</Nav.Link>} id="basic-nav-dropdown" show={show1}*/}
                    {/*             onMouseEnter={showDropdown1}*/}
                    {/*             onMouseLeave={hideDropdown1}>*/}
                    {/*    /!*<NavDropdown.Item href="">Doctores</NavDropdown.Item>*!/*/}
                    {/*    <NavDropdown.Item style={{width:'150px'}} href="/services/doctors">*/}
                    {/*        Doctores*/}
                    {/*    </NavDropdown.Item>*/}
                    {/*    <NavDropdown.Item style={{width:'150px'}} href="/services/companies">*/}
                    {/*        Empresas*/}
                    {/*    </NavDropdown.Item>*/}
                    {/*</NavDropdown>*/}

                    <hr className="custom-divider"/>

                    <NavDropdown title={<Link to='/products'>Productos</Link>} id="basic-nav-dropdown" show={show2}
                                 onMouseEnter={showDropdown2}
                                 onMouseLeave={hideDropdown2}
                    >
                        <NavDropdown.Item href="/products/monitor">
                            Monitor
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/products/stethoscope">
                            Estetoscopio
                        </NavDropdown.Item>
                    </NavDropdown>

                    <hr className="custom-divider"/>

                    <Nav.Link href="/contact">
                        Contáctenos
                    </Nav.Link>

                    <hr className="custom-divider"/>

                    <Nav.Link href="#home">

                        <Link to={{pathname: "https://portal.prevengoapp.com"}} target="_blank">
                            <Button
                                size={"large"}
                                style={{borderColor: '#44d7a0', color: '#44d7a0'}}
                                className="custom-login-button">Ingresa Ahora
                            </Button>
                        </Link>

                    </Nav.Link>

                </Nav>
            </Navbar.Collapse>
        </React.Fragment>
        // <React.Fragment>
        //
        //
        // </React.Fragment>
    );
}

export default NavigationBar;
