const data = [
    {
        description: 'Administrar tu Perfil',
    },
    {
        description: 'Administrar tus Condiciones Médicas',
    },
    {
        description: 'Realizar Consultas',
    },
    {
        description: 'Realizar mediciones con tu celular: frecuencia cardiaca y respiratoria',
    },
    {
        description: 'Realizar mediciones con el Monitor de Salud TODO EN UNO',
    },
    {
        description: 'Realizar mediciones con el Estetoscopio Digital',
    },
    {
        description: 'Ver el historial de mediciones',
    }
];

export default data;
