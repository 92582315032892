import React, {Component} from 'react'
import Navbar from "./Layout/Navbar";
import Content from "./Layout/Content";
import Footer from "./Layout/Footer";
import Overlay from "./Layout/OverlayContent";
import "react-light-notifications/lib/main.css";
import {
  NotificationContainer
} from "react-light-notifications";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import "./components/stylesheet";
import TermsConditions from "./Pages/TermsAndConditions";

const App = () => {
    return (
        <React.Fragment>
            <Router>
                <Switch>
                    <Content/>
                </Switch>
                <Route path={'/terms&conditions'} exact={true} component={TermsConditions}/>
            </Router>
        </React.Fragment>
    )
}

export default App;
