import React, {Component} from "react";
import {Card, Image, Button, Timeline} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import {List, Avatar} from 'antd';
import data from "../data/section1";
import img_001 from "../../../../../Assets/img/services/img_001.png"


const renderItems = (begin, end) =>{
    let timeLineItems = [];
    for(let i = begin; i <= end; i++){
        timeLineItems.push(
            <Timeline.Item color={"white"}>
                <p className={"custom_text_2"} style={{color:'white'}}>
                    {data[i].description}
                </p>
            </Timeline.Item>
        )}
    return timeLineItems;
}

const Section1 = () => {

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <h2 className="custom_subtitle" style={{color: '#ffffff', marginTop: '50px', textAlign:'center'}}>
                        <span>En tu menú principal podrás</span>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4}>
                    <Timeline mode="right">
                        { renderItems(0, 3).map((item) =>{
                            return item
                        })}
                    </Timeline>
                </Col>
                <Col lg={4} >
                    <p style={{textAlign:'center', marginBottom:'0px'}}>
                        <Image alt={'logo'} src={img_001} style={{width: '300px', display:'block'}} fluid preview={false}/>
                    </p>
                </Col>

                <Col xs={12} lg={4}>
                    <Timeline>
                        { renderItems(4, 6).map((item) =>{
                            return item
                        })}
                    </Timeline>
                </Col>

            </Row>
            </React.Fragment>

    )

}

export default Section1;
