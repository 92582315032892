import React, {Component, useEffect} from 'react'
import {Container} from "react-bootstrap";
import Section from "./components/Section";
import Overlay from "../../../Layout/OverlayContent";
import Navbar from "../../../Layout/Navbar";
import Footer from "../../../Layout/Footer";
import {NotificationContainer} from "react-light-notifications";

const GeneralSection = () => {

    useEffect(() =>{
        window.scrollTo(0,0)
    });

    return (
        <React.Fragment>
            <Overlay/>
                <Navbar/>
            <div style={{width: '100%'}}>
                <Container>
                    <Section />
                </Container>
            </div>
            <Footer/>
            <NotificationContainer/>
        </React.Fragment>
    )

}

export default GeneralSection
