import React, {Component} from "react";
import {Navbar, Nav, NavDropdown, Container, Col, Row, NavItem} from "react-bootstrap";
import Logo from "./components/Logo";
import NavigationBar from "./components/NavigationBar";
import '../../../Assets/css/customNav.css';


const Header = () => {
    return (

            <Navbar collapseOnSelect expand="lg" style={{padding: '20px 0 20px 0'}} className="custom-header fixed-top">
                <Container>
                    <Logo/>
                    <NavigationBar />
                </Container>
            </Navbar>

    );
}

export default Header;
