import React, {Component} from "react";
import {Card, Image, Button} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import logo from "../../../../Assets/img/branding/prevengo.png";
import image_001 from "../../../../Assets/img/home/home_section2_001.png";
import data from "../data/section1";

class Section2 extends Component {

    render() {
        return (
            <Row>
                <Col sm={12}>
                    <div className="custom-title">
                        <h2>Tecnología para la salud</h2>
                    </div>

                </Col>
                <Col xs={12} xl={8}>

                    <div className="custom-paragraph">
                        <p>
                            La experiencia vivida con la pandemia mundial de Covid-19 nos ha demostrado la importancia
                            de la
                            incorporación de las tecnologías en el ámbito de la salud, y ha permitido romper más
                            rápidamente
                            las barreras culturales de usuarios pacientes y doctores.
                        </p>

                    </div>


                </Col>
                <Col xs={12} xl={4}>
                    <div style={{float:'right'}}>
                        <Image
                        className="custom-img-size-1"
                        alt={'logo'}
                        src={image_001}
                        fluid
                        preview={false}
                    />
                    </div>

                </Col>

            </Row>
        )
    }
}

export default Section2;
