import React, {Component} from "react";
import {Card, Image, Button} from "antd";
import {Col, Row, Container} from "react-bootstrap";
import data from "../data/section1";
import img_02 from '../../../../../Assets/img/services/img_002.png'
import chatbot from "../../../../../Assets/img/services/chatboot.png";

const Section4 = () => {

    return (

        <React.Fragment>
            <Row>
                <Col>
                    <h2 className="decorated custom_subtitle" style={{color: '#44d7a0'}}>
                        <span>Mediciones Especializadas</span>
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col xs={12} lg={6} className={"custom_text_3"}>
                    <p>
                        Contamos con dispositivos de salud integrados a la plataforma, lo que le permite contar con
                        mediciones especializadas para su consumo. Podrá autorizar a su doctor de cabecera para que
                        visualice la información a través de la plataforma web.
                    </p>

                </Col>
                <Col xs={12} lg={6}>
                    <p style={{textAlign: 'right'}}>
                        <Image alt={'logo'} src={img_02} className={'custom_img_3'}
                               fluid
                               preview={false}/>
                    </p>
                </Col>
            </Row>


        </React.Fragment>

    )
}

export default Section4;
