import React from "react";
import {FaWhatsapp} from "react-icons/fa"
import "../../../Assets/css/whatsappButton.css";

const WhatsAppButton = () => {
    return (
        <a href="https://wa.me/message/4OIAIIMIL5NWH1"
           className="float" target="_blank">
            <FaWhatsapp className="my-float"/>
        </a>
    )
}

export default WhatsAppButton;
