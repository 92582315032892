import img_003 from "../../../../../Assets/img/services/img_003.png"


const data = [
    {
        img: img_003,
        description: 'Creación de usuario(s) en la plataforma web para los doctores o clínica',
    },
    {
        img: img_003,
        description: 'Relación de pacientes bajo la estructura del doctor o clínica',
    },
    {
        img: img_003,
        description: 'Los dispositivos pueden ser reusados por el centro médico o clínica. Las medidas de higiene quedan bajo la responsabilidad de ellos',
    }
];

export default data;
