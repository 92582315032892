import React, {Component} from 'react'
import Carousel from "./components/Carousel"
import {Container} from "react-bootstrap";
import Slider from "./components/Carousel";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Overlay from "../../Layout/OverlayContent";
import Navbar from "../../Layout/Navbar";
import Footer from "../../Layout/Footer";
import {NotificationContainer} from "react-light-notifications";


export class Home extends Component {
    state = {
        resumeData: {},
    }

    componentDidMount() {
        //this.getResumeData();
    }

    render() {
        return (
            <React.Fragment>
                <Overlay/>
                <Navbar/>

                <div style={{width: '100%', backgroundColor: '#44d7a0'}}>
                    <Container style={{height: '400px'}}>
                        <Slider/>
                    </Container>
                </div>

                <div style={{width: '100%', paddingTop: '75px', paddingBottom: '75px'}}>
                    <Container>
                        <Section1/>
                    </Container>
                </div>

                <div style={{width: '100%', backgroundColor: '#f5f5f5'}}>
                    <Container>
                        <Section2/>
                    </Container>
                </div>

                <div style={{width: '100%', paddingTop: '75px', paddingBottom: '75px'}}>
                    <Container>
                        <Section3/>
                    </Container>
                </div>
                <Footer/>
            <NotificationContainer/>
            </React.Fragment>
        )
    }
}

export default Home
